/* Custom styles for the slider arrows */
.slick-prev,
.slick-next {
  width: 30px; /* Adjust the width to make it smaller */
  height: 30px; /* Adjust the height to make it smaller */
  background-color: white; /* Arrow background color */
  border-radius: 50%; /* Make the arrow buttons round */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust the font size for arrow icons */
  opacity: 0.8;
  z-index: 2;
  transition: opacity 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 1; /* Full opacity on hover */
}

/* Positioning the arrows outside the image */
.slick-prev {
  left: -20px; /* Adjust the left position */
}

.slick-next {
  right: -20px; /* Adjust the right position */
}

/* Adjust arrow icons size */
.slick-prev:before,
.slick-next:before {
  font-size: 16px; /* Adjust the size of the arrow icon */
  color: black; /* Change arrow color */
}
